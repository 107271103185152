import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate de React Router
import { SelectorClientes } from '../Selectores/SelectorClientes ';

export const DialogoGestionClientes = ({ abierto, onClose }) => {
    const [clienteId, setClienteId] = useState(null);
    const navigate = useNavigate();

    const manejarSeleccionCliente = (cliente) => {
        setClienteId(cliente ? cliente.idCliente : null);
        console.log(cliente.idCliente)
    };

    const manejarGestionCliente = () => {
        onClose();
        navigate(`/gestion-clientes/${clienteId}`);
    };

    return (
        <Dialog open={abierto} onClose={onClose}>
            <DialogTitle>Gestión de clientes</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Selecciona el cliente sobre el que quieres hacer gestiones:
                </DialogContentText>
                <SelectorClientes onClienteSeleccionado={manejarSeleccionCliente} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={manejarGestionCliente}>Gestionar</Button>
            </DialogActions>
        </Dialog>
    );
};
