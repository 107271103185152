import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Toolbar, Typography, Button, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

export const Header = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleButtonClick = () => {
        if (location.pathname === '/portalEmpleados') {
            navigate('/');
        } else {
            window.location.href = 'https://empleados.vmestilistes.com';
        }
    };

    const buttonText = location.pathname === '/portalEmpleados' ? 'Volver' : 'Volver';


    return (
        <AppBar position="static" color="default" elevation={0} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, background: '#ffffff' }}>
            <Toolbar sx={{ flexWrap: 'wrap' }}>
                <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" noWrap sx={{
                    flexGrow: 1, fontFamily: 'Stephanie Marie JF',fontSize: isMobile ? '1.2rem' : '2rem'}}>
                    VmEstilistes
                </Typography>
                <nav>
                    {/* Here you can add your navigation links */}
                </nav>
                <Button
                    color="primary"
                    onClick={handleButtonClick}
                    sx={{
                        fontFamily: 'Stephanie Marie JF',
                        fontSize: isMobile ? '1.2rem' : '2rem',
                        color: 'black',
                        textTransform: 'none',
                    }}
                >
                    {buttonText}
                </Button>
            </Toolbar>
        </AppBar>
    );
};

