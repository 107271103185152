import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const NavBarFecha = ({ fechaSeleccionada, cambiarDia }) => (
    <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Grid item>
            <IconButton onClick={() => cambiarDia(-1)}>
                <ArrowBackIosNewIcon />
            </IconButton>
        </Grid>
        <Grid item>
            <Typography variant="h6">
                {format(fechaSeleccionada, 'PPPP', { locale: es })}
            </Typography>
        </Grid>
        <Grid item>
            <IconButton onClick={() => cambiarDia(1)}>
                <ArrowForwardIosIcon />
            </IconButton>
        </Grid>
    </Grid>
);

export default NavBarFecha;