export const estaHoraOcupada = (hora, citasPorDia) => {
    let citaEncontrada = null;
    let esInicioDeCita = false;
    Object.entries(citasPorDia).forEach(([horaInicio, cita]) => {
        const inicioCita = new Date(`2024-01-01T${horaInicio}:00`);
        const finCita = cita.fin ? new Date(`2024-01-01T${cita.fin}:00`) : inicioCita;
        const horaActual = new Date(`2024-01-01T${hora}:00`);

        if (horaActual >= inicioCita && horaActual < finCita) {
            citaEncontrada = cita;
            esInicioDeCita = horaInicio === hora;
        }
    });
    return { cita: citaEncontrada, esInicioDeCita };
};
