import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, useMediaQuery, useTheme, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Box, Typography
} from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { format } from 'date-fns';
import PhoneIcon from '@mui/icons-material/Phone';
import { cargarTelefonoPorId } from '../../consultas/clientes/BuscarTelefonoPorId.';
import DeleteIcon from '@mui/icons-material/Delete'; 
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


export const TablaCitas = ({ horario, citasDelDia, fechaSeleccionada, abrirDialogoAgendar, completarCita, abrirDialogoEditar, cancelarCita, volverCita, eliminarCita, obtenerColorEstado, estaHoraOcupada }) => {
    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const fechaFormato = format(fechaSeleccionada, 'yyyy-MM-dd');
    const [telefonosClientes, setTelefonosClientes] = useState({});
    const [dialogoEliminarAbierto, setDialogoEliminarAbierto] = useState(false);
    const [citaParaEliminar, setCitaParaEliminar] = useState({ fecha: '', hora_inc: '' });

    const mostrarDialogoEliminar = (fecha, hora_inc) => {
        setCitaParaEliminar({ fecha, hora_inc });
        setDialogoEliminarAbierto(true);
    };

    const manejarEliminarCita = async () => {
        await eliminarCita(citaParaEliminar.fecha, citaParaEliminar.hora_inc);
        setDialogoEliminarAbierto(false);
    };

    const cerrarDialogoEliminar = () => {
        setDialogoEliminarAbierto(false);
    };

    useEffect(() => {
        const cargarTelefonosClientes = async () => {
            let nuevosTelefonos = {};

            const citasArray = Object.values(citasDelDia);

            const promesasCargarTelefonos = citasArray.map(async (cita) => {
                if (cita.id_cliente && !nuevosTelefonos.hasOwnProperty(cita.id_cliente)) {
                    try {
                        const respuesta = await cargarTelefonoPorId(cita.id_cliente);

                        if (respuesta && respuesta.Num_telefono) {
                            nuevosTelefonos[cita.id_cliente] = respuesta.Num_telefono;
                        } else {
                            console.log(`No se encontró teléfono para el cliente ${cita.id_cliente}`);
                        }
                    } catch (error) {
                        console.error(`Error al cargar el teléfono del cliente con ID ${cita.id_cliente}:`, error);
                        nuevosTelefonos[cita.id_cliente] = null;
                    }
                }
            });

            await Promise.all(promesasCargarTelefonos);

            setTelefonosClientes(nuevosTelefonos);
        };

        if (Object.keys(citasDelDia).length > 0) {
            cargarTelefonosClientes();
        } else {
            console.log('No hay citas para cargar.');
        }
    }, [citasDelDia]);



    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Hora</TableCell>
                        {!isXsScreen && <TableCell>Cliente</TableCell>}
                        <TableCell>Estilista</TableCell>
                        <TableCell>Servicio</TableCell>
                        {!isXsScreen && <TableCell>Estado</TableCell>}
                        <TableCell>Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {horario.map((tiempo, index) => {
                        const { cita, esInicioDeCita } = estaHoraOcupada(tiempo, citasDelDia);
                        const ocupado = cita !== null;

                        if (ocupado && !esInicioDeCita) return null;

                        return (
                            <TableRow
                                key={index}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 },
                                    backgroundColor: ocupado ? obtenerColorEstado(cita.estado) : '',
                                }}
                            >
                                <TableCell>{ocupado ? `${tiempo}-${cita.fin}` : tiempo}</TableCell>
                                {!isXsScreen && <TableCell>{ocupado ? cita.cliente : '---'}</TableCell>}
                                <TableCell>{ocupado ? cita.estilista : '---'}</TableCell>
                                <TableCell sx={{
                                    ...(isXsScreen && { maxWidth: 80 })
                                }}>
                                    <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
                                        {ocupado ? cita.servicio : '---'}
                                    </Typography>
                                </TableCell>
                                {!isXsScreen && <TableCell>{ocupado ? cita.estado : '---'}</TableCell>}
                                <TableCell align="left">
                                    {!ocupado ? (
                                        <Button variant="contained" color="primary" size="small" onClick={() => abrirDialogoAgendar(tiempo)}>
                                            Agendar
                                        </Button>
                                    ) : (
                                        <>                                            
                                            <IconButton color="primary" size="small" onClick={() => completarCita(fechaFormato, tiempo)}>
                                                <DoneAllIcon />
                                            </IconButton>

                                            <IconButton color="primary" size="small" onClick={() => abrirDialogoEditar(tiempo)}>
                                                <EditIcon />
                                            </IconButton>

                                            <IconButton color="primary" size="small" onClick={() => cancelarCita(fechaFormato, tiempo)}>
                                                <CancelIcon />
                                            </IconButton>

                                            <IconButton color="primary" size="small" onClick={() => mostrarDialogoEliminar(fechaFormato, tiempo)}>
                                                <DeleteIcon />
                                            </IconButton>

                                            <IconButton color="primary" size="small" onClick={() => volverCita(fechaFormato, tiempo)}>
                                                <PendingActionsIcon />
                                            </IconButton>

                                                {telefonosClientes[cita.id_cliente] && (
                                                    <IconButton
                                                        color="primary" size="small"
                                                        onClick={() => {
                                                            const url = `https://wa.me/${telefonosClientes[cita.id_cliente]}`;
                                                            window.open(url, '_blank');
                                                        }}
                                                    >
                                                        <PhoneIcon />
                                                    </IconButton>
                                                )}                                                
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <Dialog
                open={dialogoEliminarAbierto}
                onClose={cerrarDialogoEliminar}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"¿Estás seguro de que quieres eliminar esta cita?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Esta acción no se puede deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cerrarDialogoEliminar}>Cancelar</Button>
                    <Button onClick={manejarEliminarCita} autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </TableContainer>
        
    );
};