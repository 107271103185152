import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Button, Divider, Box } from '@mui/material';
import { format, addDays, startOfWeek, getDay, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { cargarCitas } from '../consultas/citas/CargarCitas';
import { obtenerColorEstado } from '../components/Citas/Acciones/ObtenerColores';
import { cargarEstilistaPorId } from '../consultas/estilistas/BuscarEstilistaPorId';
import { useNavigate } from 'react-router-dom';

const obtenerDiasDeLaSemana = (fecha) => {
    let ajusteDias = getDay(fecha) === 1 ? 1 : 0;
    let inicioSemana = startOfWeek(addDays(fecha, ajusteDias), { weekStartsOn: 2 });
    return Array.from({ length: 5 }, (_, i) => addDays(inicioSemana, i));
};

const horas = Array.from({ length: 49 }, (_, i) => {
    const hora = Math.floor(i / 4) + 8;  // +8 para empezar desde las 8 AM
    const minutos = (i % 4) * 15; 
    return `${hora < 10 ? '0' + hora : hora}:${minutos === 0 ? '00' : minutos}`;
}).filter(hora => hora <= "20:00"); // Asegura que no se exceda de las 20:00 PM


export const Lista = () => {
    const [fecha, setFecha] = useState(new Date());
    const [citas, setCitas] = useState([]);
    const navigate = useNavigate();

    const cargarNombresEstilistas = async (citas) => {
        const promesas = citas.map(async cita => {
            const estilistaData = await cargarEstilistaPorId(cita.id_estilista);
            console.log(cita.id_estilista)
            console.log(estilistaData)
            return { ...cita, nombre_estilista: estilistaData.nombre_estilista || 'Nombre no encontrado' };
        });
        return Promise.all(promesas);
    };

    useEffect(() => {
        cargarCitas().then(data => {
            cargarNombresEstilistas(data).then(citasConNombres => {
                setCitas(citasConNombres);
                console.log("Citas con nombres de estilistas cargadas:", citasConNombres);
            });
        }).catch(err => console.error('Error al cargar las citas:', err));
        if (getDay(new Date()) < 2) {
            setFecha(addDays(new Date(), 1));
        }
    }, []);


    const dias = obtenerDiasDeLaSemana(fecha);
    const cambiarSemana = (dias) => {
        setFecha(addDays(fecha, dias));
    };

    const abrirDetalleDia = (dia) => {
        console.log("Abrir detalles para: ", format(dia, 'yyyy-MM-dd'));
        navigate(`/lista/${format(dia, 'yyyy-MM-dd')}`);
    };

    const obtenerDatosCitas = (dia) => {
        const diaFormato = format(dia, 'yyyy-MM-dd');
        const citasDelDia = citas.filter(cita => format(parseISO(cita.fecha), 'yyyy-MM-dd') === diaFormato);
        return horas.map(hora => {
            for (let cita of citasDelDia) {
                const inicioCita = cita.hora_inc;
                const finCita = cita.hora_fin;
                if (hora >= inicioCita && hora < finCita) {
                    return {
                        tieneCita: true,
                        esInicio: hora === inicioCita,
                        texto: hora === inicioCita ? `${inicioCita}-${finCita}` : "",
                        color: obtenerColorEstado(cita.estado),
                        servicio: hora === inicioCita ? cita.servicio : "",
                        estilista: hora === inicioCita ? cita.nombre_estilista : ""  // Usa el nombre del estilista cargado
                    };
                }
            }
            return { tieneCita: false, texto: hora, color: 'transparent', esFinal: true };
        });
    };

    return (
        <Box sx={{ margin: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <Button variant="contained" color="primary" onClick={() => cambiarSemana(-7)}>Semana Anterior</Button>
                <Button variant="contained" color="secondary" onClick={() => cambiarSemana(7)}>Siguiente Semana</Button>
            </Box>
            <Grid container spacing={2}>
                {dias.map((dia, index) => (
                    <Grid item xs={12} sm key={index} onClick={() => abrirDetalleDia(dia)}>
                        <Paper elevation={3} sx={{ padding: 2, minHeight: '100%', cursor: 'pointer' }}>
                            <Typography variant="h5" sx={{ marginBottom: 2 }}>
                                {format(dia, 'EEEE, dd MMMM', { locale: es }).replace(/^\w/, c => c.toUpperCase())}
                            </Typography>
                            {obtenerDatosCitas(dia).map((info, idx, arr) => (
                                <React.Fragment key={idx}>
                                    <Typography variant="body2" sx={{
                                        padding: 1,
                                        backgroundColor: info.color,
                                        minHeight: '23px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: info.esInicio ? 'space-between' : 'flex-start'
                                    }}>
                                        <span>{info.texto}</span>
                                        {info.esInicio && <span style={{ textAlign: 'center', flexGrow: 1 }}>{info.servicio} - {info.estilista}</span>}
                                    </Typography>
                                    {info.esFinal && idx < arr.length - 1 && <Divider />}
                                </React.Fragment>
                            ))}
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};