import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress, Dialog, DialogContent, DialogActions, Button, DialogTitle } from '@mui/material';
import { cargarClientes } from '../../../consultas/clientes/CargarClientes';
import { añadirClientes } from '../../../consultas/clientes/AddCilientes';


export const SelectorClientes = ({ onClienteSeleccionado, valorActual, actualizarClienteSeleccionado }) => {
    const [clientes, setClientes] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [dialogoAbierto, setDialogoAbierto] = useState(false);
    const [nuevoCliente, setNuevoCliente] = useState({
        Nombre: '',
        Apellidos: '',
        Num_telefono: '',
    });

    useEffect(() => {
        cargarClientesInicial();
    }, []);

    const cargarClientesInicial = async () => {
        setCargando(true);
        try {
            const clientesCargados = await cargarClientes();
            setClientes(clientesCargados);
        } catch (error) {
            console.error('Error al cargar los clientes:', error);
        } finally {
            setCargando(false);
        }
    };

    const handleAddClient = async () => {
        setDialogoAbierto(false);
        try {
            const response = await añadirClientes(nuevoCliente);
            console.log('Cliente añadido:', response);
            const clientesActualizados = await cargarClientes();
            setClientes(clientesActualizados);
            const nuevoClienteAñadido = clientesActualizados.find(c => c.id === response.id);
            onClienteSeleccionado(nuevoClienteAñadido);
            setNuevoCliente({ Nombre: '', Apellidos: '', Num_telefono: '' });
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    };


    const manejarCambio = (event, nuevoValor) => {
        if (nuevoValor && nuevoValor.Nombre === "Añadir nuevo cliente...") {
            setDialogoAbierto(true);
        } else {
            onClienteSeleccionado(nuevoValor);
        }
    };

    const handleInputChange = (field, value) => {
        setNuevoCliente(prev => ({ ...prev, [field]: value }));
    };

    return (
        <>
            <Autocomplete
                value={valorActual}
                onChange={manejarCambio}
                options={[...clientes, { Nombre: "Añadir nuevo cliente..." }]}
                getOptionLabel={(option) => option.Nombre || ''}
                filterOptions={(options, params) => {
                    const filtered = options.filter(option => option.Nombre.toLowerCase().includes(params.inputValue.toLowerCase()));
                    if (params.inputValue !== '') {
                        filtered.push({ Nombre: "Añadir nuevo cliente..." });
                    }
                    return filtered;
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Cliente"
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {cargando ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
            <Dialog open={dialogoAbierto} onClose={() => setDialogoAbierto(false)}>
                <DialogTitle>Añadir Nuevo Cliente</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="nombre"
                        label="Nombre"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={nuevoCliente.Nombre}
                        onChange={(e) => handleInputChange('Nombre', e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="apellidos"
                        label="Apellidos"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={nuevoCliente.Apellidos}
                        onChange={(e) => handleInputChange('Apellidos', e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="num_telefono"
                        label="Número de Teléfono"
                        type="tel"
                        fullWidth
                        variant="outlined"
                        value={nuevoCliente.Num_telefono}
                        onChange={(e) => handleInputChange('Num_telefono', e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogoAbierto(false)}>Cancelar</Button>
                    <Button onClick={handleAddClient}>Añadir</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};