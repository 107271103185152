export const crearHorario = (inicio, fin) => {
    const franjas = [];
    for (let hora = inicio; hora < fin; hora++) {
        for (let minuto = 0; minuto < 60; minuto += 15) {
            const tiempo = `${hora.toString().padStart(2, '0')}:${minuto.toString().padStart(2, '0')}`;
            franjas.push(tiempo);
        }
    }
    return franjas;
};
