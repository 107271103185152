import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate de React Router
import { SelectorClientes } from '../Selectores/SelectorClientes ';
import { SelectorEstilistas } from '../Selectores/SelectorEstilistas';

export const DialogoGestionEstilistas = ({ abierto, onClose }) => {

    const [estilistaId, setClienteId] = useState(null);
    const navigate = useNavigate();

    const manejarSeleccionEstilista = (estilista) => {
        setClienteId(estilista ? estilista.idEstilista : null);
        console.log(estilista.idEstilista)
    };

    const manejarGestionEstilista = () => {
        onClose();
        navigate(`/gestion-estilista/${estilistaId}`);
    };

    return (
        <Dialog open={abierto} onClose={onClose}>
            <DialogTitle>Gestión de Estilistas</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Selecciona el estilista sobre el que quieres hacer gestiones:
                </DialogContentText>
                <SelectorEstilistas onEstilistaSeleccionado={manejarSeleccionEstilista} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={manejarGestionEstilista}>Gestionar</Button>
            </DialogActions>
        </Dialog>
    );
};
