export const obtenerColorEstado = (estado) => {
    switch (estado) {
        case 'Completada':
            return '#C8E6C9';
        case 'Cancelada':
            return '#FFCDD2';
        case 'Agendada':
            return '#FFF9C4';
        default:
            return '';
    }
};